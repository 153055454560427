import type { BoxProps } from '@chakra-ui/react';
import { AspectRatio, Box } from '@chakra-ui/react';

export const TitleSignature: React.FC<BoxProps> = props => {
  return (
    <Box w={{ base: 36 }} {...props}>
      <AspectRatio ratio={106 / 11}>
        <Box
          as='svg'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 106 11'
          transition='all 250ms'
        >
          <g fill='currentColor'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M63.44 5.02c-13.917.168-27.84.833-41.696 2.02-6.588.565-14.238.494-20.627 2.42a.34.34 0 0 0-.25.416c.013.06.07.257.324.267.028.001.222-.026.297-.036 1.886-.25 3.766-.54 5.654-.775C13.661 8.517 20.2 7.9 26.746 7.362c8.691-.718 17.456-1.1 26.17-1.399 4.77-.163 9.654.054 14.44-.283 1.756-.01 3.513-.012 5.27-.006 7.346.026 14.683.306 22.017.69 2.358.122 4.156.255 6.464.345.877.035 2.268.058 3.235.074l.655.006c.015.003.03.003.047.003.057 0 .085-.004.09-.004.328-.048.298-.36.29-.407-.002-.01-.051-.272-.334-.282-.067-.003-.551-.006-.737-.008-.962-.016-2.347-.04-3.219-.072-2.305-.09-4.102-.223-6.456-.346-7.344-.384-14.692-.665-22.049-.691-.898-.003-1.798-.004-2.697-.003a.336.336 0 0 0-.246-.206c-.14-.036-1.136-.068-1.52-.1-2.846-.235-2.762-.224-5.893-.416-4.743-.292-5.465-.359-10.397-.482-8.96-.224-17.923-.206-26.884-.184-4.424.013-9.16.313-13.652-.248 1.48-.174 2.966-.301 4.449-.447 5.063-.495 10.13-.802 15.215-1.018 12.195-.518 24.41-.724 36.6-.018-2.594.025-5.188.083-7.783.12-10.564.155-21.23.039-31.77.843a.347.347 0 0 0 .052.69c10.527-.803 21.178-.686 31.727-.84 3.92-.058 7.838-.16 11.757-.13 1.346.012 2.69.061 4.036.078.266.004.95.057 1.048.035a.337.337 0 0 0 .277-.288c.007-.047.03-.319-.301-.416-2.258-.661-6.424-.63-8.618-.76-12.341-.734-24.707-.53-37.054-.006-5.097.217-10.177.526-15.254 1.023-1.774.173-3.551.322-5.318.552-.285.037-.926.091-1.236.148a.949.949 0 0 0-.265.08.345.345 0 0 0-.19.314c-.001.07.027.313.365.38 5.145 1.011 10.743.614 15.916.6 8.956-.022 17.912-.04 26.866.184 4.92.123 5.64.188 10.372.48l1.209.074Z'
            />
          </g>
        </Box>
      </AspectRatio>
    </Box>
  );
};
