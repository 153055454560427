import React, { createContext, useContext } from 'react';

export const QueryKeysContext = createContext({});

export const QueryKeysProvider: React.FC = ({
  children,
  queryKeys = {}
}: any) => {
  return (
    <QueryKeysContext.Provider value={queryKeys}>
      {children}
    </QueryKeysContext.Provider>
  );
};

export const useQueryKeys = (path?: string): any => {
  return useContext(QueryKeysContext);
};
