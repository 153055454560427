import React, { useEffect, useRef, useState } from 'react';

import { Button, Icon, VStack, Text } from '@chakra-ui/react';

import { useGoogleLogin } from '@react-oauth/google';
import { useToast, useTranslation } from 'hooks';
import { useAuth } from 'context';
import { BsGoogle } from 'react-icons/bs';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

export const GoogleAuthButton = ({ title, type }) => {
  const googlebuttonref = useRef(null);
  const toast = useToast();
  const { mutateGoogleAuth } = useAuth();
  const { t } = useTranslation();
  const router = useRouter();
  const [backUrl, setBackUrl] = useState('');
  useEffect(() => {
    if (router.asPath) setBackUrl(router.asPath);
  }, [router.asPath]);

  const login = useGoogleLogin({
    onSuccess: async codeResponse => {
      try {
        const access_token = String(codeResponse.access_token);
        await signIn('google', {
          redirect: true,
          callbackUrl: backUrl
        });
      } catch (e) {
        console.error(e);
      }
    },
    onError: error => {
      toast({
        title: t('general.toastMessages.error'),
        description: t('general.toastMessages.error_message'),
        status: 'error'
      });
    }
  });

  return (
    <>
      <VStack align={'center'} spacing='2'>
        <Button
          variant={'none'}
          color='#fff'
          sx={{
            span: {
              marginInlineEnd: '0rem'
            }
          }}
          leftIcon={
            <Icon
              as={BsGoogle}
              fontSize='18px'
              marginInlineEnd={'0rem !important'}
            />
          }
          ref={googlebuttonref}
          onClick={() => login()}
          colorScheme='blackAlpha'
          backgroundColor={'black'}
          width='40px'
          height={'40px'}
        />

        <Text>{title}</Text>
      </VStack>
    </>
  );
};
