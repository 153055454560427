import { Box, HStack, Spacer } from '@chakra-ui/react';
import {
  AddCommentButton,
  DownVoteButton,
  ShareMenu,
  UpVoteButton
} from 'components/questions/shared';
import { useCurrentUser } from 'hooks';
import { Answer } from 'types/answers';

interface Props {
  onCommentsOpen: () => void;
  questionUrl: string;
  refetch?: () => void;
  dataCallBack?: () => void;
}

export const ActionBar: React.FC<
  Props &
    Pick<
      Answer,
      'ID' | 'comments_count' | 'current_vote' | 'votes' | 'title' | 'userSlug'
    >
> = ({
  ID,
  comments_count,
  current_vote,
  onCommentsOpen,
  votes,
  questionUrl,
  title,
  refetch,
  userSlug,

  answer_id,
  myAnswer,
  dataCallBack
}) => {
  const { upvotes, downvotes } = votes;

  const user = useCurrentUser();

  return (
    <HStack align={'center'}>
      {user?.slug !== userSlug && (
        <>
          <UpVoteButton
            refType='answer'
            refId={answer_id}
            count={upvotes}
            currentVote={current_vote}
            callback={dataCallBack}
          />
          <Box color='#999999' fontSize='md' fontWeight={'bold'}>
            &#183;
          </Box>
          <DownVoteButton
            refType='answer'
            refId={answer_id}
            count={downvotes}
            currentVote={current_vote}
            callback={dataCallBack}
          />
          <Box color='#999999' fontSize='md' fontWeight={'bold'}>
            &#183;
          </Box>
          <AddCommentButton onClick={onCommentsOpen} count={comments_count} />
        </>
      )}

      <Spacer />
      <ShareMenu url={questionUrl} title={title} />
    </HStack>
  );
};
