import {
  Book,
  Bookmark2,
  Briefcase,
  Cpu,
  Gallery,
  Home,
  LoginCurve,
  Notepad2,
  People,
  Profile2User,
  TrendUp,
  VideoCircle
} from 'iconsax-react';
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaPinterest,
  FaTiktok,
  FaTwitter,
  FaViber,
  FaYoutube
} from 'react-icons/fa';
import {
  FcHome,
  FcBookmark,
  FcReading,
  FcBusinessman,
  FcClapperboard,
  FcConferenceCall,
  FcBullish,
  FcDocument
} from 'react-icons/fc';

export type Menu = {
  id?: string | number;
  url: string;
  title: string;
  icon?: 'string';
  file: string | boolean;
  child_items?: Menu[];
  setShowSubMenu: (string) => void;
  showSubMenu?: string;
  isChild?: boolean;
  children?: any;
};

export const menuIcons = {
  login: LoginCurve,
  home: FcHome,
  people: FcConferenceCall,
  notepad: FcDocument,
  trend_up: FcBullish,
  video_circle: FcClapperboard,
  profile_user: Profile2User,
  cpu: Cpu,
  gallery: Gallery,
  briefcase: Briefcase,
  book: FcReading,
  facebook: FaFacebook,
  twitter: FaTwitter,
  youtube: FaYoutube,
  instagram: FaInstagram,
  tiktok: FaTiktok,
  pinterest: FaPinterest,
  google_news: FaGoogle,
  viber: FaViber,
  bookmark: FcBookmark
};
