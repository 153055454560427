import Script from 'next/script';

export const GoogleAnalytics2 = () => {
  return (
    <>
      <Script src='https://www.googletagmanager.com/gtag/js?id=G-R4VLPND4G7' />
      <Script id='google-analytics'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-R4VLPND4G7');
        `}
      </Script>
    </>
  );
};
