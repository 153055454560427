import { Icon, IconButton } from '@chakra-ui/react';
import { showLoginModal, useLayoutContext } from 'context/layout';
import { useBookmark, useToast, useTranslation } from 'hooks';
import { ArchiveAdd, ArchiveTick } from 'iconsax-react';
import { useSession } from 'next-auth/react';
import React from 'react';

const BookmarkButton = ({
  isBooked,
  id,
  setIsBooked,
  refetchBookmarks,
  loading
}: any) => {
  const { data: session } = useSession();
  const { t } = useTranslation();

  const toast = useToast();

  const callBack = ({ data, isBookMarked }) => {
    setIsBooked(isBookMarked);
    refetchBookmarks?.();
  };
  const { mutateBookMark, isLoading } = useBookmark(id, callBack);
  const { dispatch, state } = useLayoutContext();
  return (
    <IconButton
      icon={
        <Icon
          as={isBooked ? ArchiveTick : ArchiveAdd}
          variant={isBooked ? 'Bold' : 'Linear'}
        />
      }
      aria-label={t('bio.delete_from_save')}
      size={'md'}
      ml='auto !important'
      isLoading={isLoading || loading}
      isDisabled={isLoading || loading}
      onClick={() => {
        if (!session?.accessToken) {
          toast({
            title: t('general.toastMessages.alert'),
            description: t('general.toastMessages.login_first'),
            status: 'warning',
            duration: 2000
          });
          dispatch(showLoginModal());
        } else {
          mutateBookMark({ type: isBooked ? 'remove' : 'add' });
        }
      }}
    />
  );
};

export default BookmarkButton;
