import { Box, UseToastOptions } from '@chakra-ui/react';
import { useToast as useChakraToast } from '@chakra-ui/react';

export const useToast = () => {
  const toast = useChakraToast();

  const fns = (params: UseToastOptions): void => {
    if (!params || Object.keys(params)?.length === 0) {
      return;
    }

    toast({
      ...params,
      duration: 3000,
      isClosable: true,
      position: 'top',
      variant: 'information',
      status: 'info',
      containerStyle: {
        backgroundColor: '#fff',
        border: '2px solid black',
        borderRadius: '5px'
      }
    });
  };

  return fns;
};
