import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  LinkBox,
  Text,
  VStack
} from '@chakra-ui/react';
import { Calendar, Microphone2 } from 'iconsax-react';
import { getFullDate, getFullDateEn, htmlParser } from 'utls';
import { NextChakraLinkOverlay } from '../wrappers';
import { Image } from './';
import { useTranslation } from 'hooks';

const PostItem: React.FC = ({
  id,
  title,
  _embedded,
  link,
  type,
  date,
  thumbnail
}) => {
  const featured_media = _embedded?.['wp:featuredmedia']?.[0] ?? {
    url: thumbnail
  };

  const isPodcast = type === 'podcast';

  const url = `${link}${isPodcast ? '?vol=1' : ''}`;

  const { locale } = useTranslation();
  const isEnglish = locale !== 'en'; 

  return (
    <LinkBox as='article' pos={'relative'} role='group'>
      <HStack align={'stretch'}>
        <VStack align={'stretch'} spacing={4} w={2 / 3}>
          <Heading
            as='span'
            fontSize={{ base: 'h4', md: '1.125rem' }}
            fontWeight='medium'
          >
            <NextChakraLinkOverlay href={url}>
              {title?.rendered ? (
                <Text
                  lineHeight={'1.5'}
                  as='span'
                  dangerouslySetInnerHTML={{ __html: title.rendered }}
                />
              ) : (
                <Text lineHeight={'1.5'} as='span'>
                  {htmlParser(title)}
                </Text>
              )}
            </NextChakraLinkOverlay>
          </Heading>
          {date && (
            <HStack spacing={2}>
              <Icon as={Calendar} boxSize={'1.125em'} color='#808080' />
              <Text lineHeight={'1.5'} as='span' color='#808080'>
                {isEnglish ? getFullDate(date) : getFullDateEn(date)}
              </Text>
            </HStack>
          )}
        </VStack>
        <Box w={1 / 3}>
          <NextChakraLinkOverlay href={url}>
            <Box pos={'relative'}>
              <Image
                alt={`image ${id}`}
                image={featured_media}
                ratio={16 / 9}
                rounded='md'
                boxShadow={'xl'}
                sizes={'(min-width: 62em) 15vw, 30vw'}
                blog={'true'}
              />
              {isPodcast && (
                <Flex
                  alignItems={'flex-start'}
                  justifyContent='flex-end'
                  pos={'absolute'}
                  inset={0}
                  p={2}
                >
                  <Center bgColor={'black'} p={1} rounded={'full'}>
                    <Icon as={Microphone2} fontSize='3xl' color='white' />
                  </Center>
                </Flex>
              )}
            </Box>
          </NextChakraLinkOverlay>
        </Box>
      </HStack>
    </LinkBox>
  );
};

export default PostItem;
