import Script from 'next/script';

export const IzootoScripts = () => {
  return (
    <>
      <Script
        id='Izooto-init'
        strategy='beforeInteractive'
        dangerouslySetInnerHTML={{
          __html: `
					window._izq = window._izq || []; window._izq.push(["init" ]); 
			  `
        }}
      />
      <Script
        id='Izooto-script'
        strategy='beforeInteractive'
        src='https://cdn.izooto.com/scripts/89685238705c96a4ace716491aa74a9fcf0c00e3.js'
      />
    </>
  );
};
