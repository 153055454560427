import { useToast, useTranslation } from 'hooks';
import { useRouter } from 'next/router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCookie } from 'react-use';
import { mutationFn, USER_TOKEN_KEY } from 'utls';

export const useDelete = ({ type, id, action, refetchAnswers }) => {
  const { t } = useTranslation();
  const [token] = useCookie(USER_TOKEN_KEY);
  const toast = useToast();
  const router = useRouter();
  return useMutation(
    () =>
      mutationFn({
        name: `delete-${action}`,
        params: { id },
        token
      }),
    {
      onSuccess: () => {
        if (type === 'question') {
          toast({
            title: t('bio.seccessful_op'),
            description: t('bio.deleted_successfully'),
            status: 'success'
          });
          router.push('/');
        } else {
          toast({
            title: t('bio.seccessful_op'),
            description: t('bio.deleted_successfully'),
            status: 'success'
          });
          refetchAnswers();
        }
      },
      onError: () => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );
};
