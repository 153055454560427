import React, { useEffect, useState, createContext, useContext } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { useSiteConfig } from 'hooks';
import { dfp } from 'utls';

export const SiteConfigContext = createContext({});
const SiteConfigProvider: React.FC = ({ children }) => {
  const { adsSlots } = useSiteConfig();
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  // Create ad slots
  useEffect(() => {
    setIsLoading(true);

    dfp.createSlots(adsSlots, true);

    setIsLoading(false);

    const handleRouteChangeStart = url => {
      if (window.location.pathname !== url) {
        setIsLoading(true);
        dfp.removeSlots();
        dfp.createSlots(adsSlots, true);
      }
    };

    const handleRouteChangeComplete = () => {
      setIsLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [adsSlots]);

  return (
    <>
      <SiteConfigContext.Provider value={{ isLoading, adsSlots }}>
        {children}
      </SiteConfigContext.Provider>
      <Script
        src='https://securepubads.g.doubleclick.net/tag/js/gpt.js'
        // strategy='afterInteractive'
        async
      />
      <Script
        async
        strategy='afterInteractive'
        id={'speakol'}
        src={'https://cdn.speakol.com/widget/js/speakol-widget-v2.js'}
      />

      {/* <Script
        async
        strategy='afterInteractive'
        id={'jubna'}
        src={'https://static.jubnaadserve.com/api/widget.js'}
      />
   */}
      <Script id='submit-blogs' strategy='afterInteractive'>
        {`(function() { var script = document.createElement('script'); script.src = "https://paperform.co/__embed.min.js";document.body.appendChild(script); })()`}
      </Script>
      <Script id='suggest-biography' strategy='afterInteractive'>
        {`(function() { var script = document.createElement('script'); script.src = "https://paperform.co/__embed.min.js";document.body.appendChild(script); })()`}
      </Script>
    </>
  );
};

export default SiteConfigProvider;
export const useAdPlace = (adPlace: string) => {
  const { adsSlots, isLoading } = useContext(SiteConfigContext);
  const ad = adsSlots?.find(({ place }) => place === adPlace);

  return {
    ...ad,
    isLoading
  };
};
