import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Center,
  chakra,
  Divider,
  Heading,
  Icon,
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text as ChakraText,
  Tfoot,
  Th,
  Thead,
  Tr,
  UnorderedList,
  Input
} from '@chakra-ui/react';
import getYouTubeID from 'get-youtube-id';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { ArrowLeft, QuoteUp } from 'iconsax-react';
import { useId } from 'react';
import dynamic from 'next/dynamic';

import { YouTube } from 'components/gutenberg/core-embed';
import { Image, StarRating, TableOfContentSkeleton } from 'components/shared';
import { wpautop } from 'utls';
import {
  ConditionalWrapper,
  NextChakraButton,
  NextChakraLink
} from 'components/wrappers';

const BioCard = dynamic(() => import('components/shared/BioCardShortcode'));
const TableOfContent = dynamic(
  () => import('components/shared/TableOfContent'),
  {
    ssr: false,
    loading: TableOfContentSkeleton
  }
);

export const mainReplacer: HTMLReactParserOptions['replace'] = props => {
  const { type, name, children, attribs, parent, data } = props;
  const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

  if (type === 'tag' && headings.includes(name)) {
    return (
      <Heading id={useId()} as={name} fontSize={name}>
        {domToReact(children, { replace: mainReplacer })}
      </Heading>
    );
  }

  if (type === 'tag' && name === 'p') {
    return (
      <ChakraText lineHeight='taller'>
        {domToReact(children, { replace: mainReplacer })}
      </ChakraText>
    );
  }

  if (type === 'tag' && name === 'pre') {
    return (
      <Box as={name} p={4} bgColor='light.500' whiteSpace={'pre-wrap'}>
        {domToReact(children, { replace: mainReplacer })}
      </Box>
    );
  }

  if (type === 'tag' && name === 'code') {
    return (
      <chakra.code
        display='block'
        fontFamily={'mono'}
        textAlign={'right'}
        sx={{
          direction: 'rtl'
        }}
      >
        {domToReact(children, { replace: mainReplacer })}
      </chakra.code>
    );
  }

  if (type === 'tag' && name === 'ol') {
    return (
      <Box lineHeight={'taller'} pl={4}>
        <OrderedList>
          {domToReact(children, { replace: mainReplacer })}
        </OrderedList>
      </Box>
    );
  }

  if (type === 'tag' && name === 'ul') {
    return (
      <Box lineHeight={'taller'}>
        <UnorderedList>
          {domToReact(children, { replace: mainReplacer })}
        </UnorderedList>
      </Box>
    );
  }

  if (type === 'tag' && name === 'li') {
    return (
      <ConditionalWrapper
        condition={parent === null}
        wrap={children => (
          <UnorderedList
            mt='2'
            paddingInline={{ base: 4, md: 8 }}
            marginInlineStart='0px !important'
          >
            {children}
          </UnorderedList>
        )}
      >
        <ListItem>
          <ChakraText as='span'>
            {domToReact(children, { replace: mainReplacer })}
          </ChakraText>
        </ListItem>
      </ConditionalWrapper>
    );
  }

  if (type === 'tag' && name === 'a') {
    const { class: className, href, target, style, ...rest } = attribs;
    const isFootNote = className?.includes('bfn-footnoteHook');

    if (className?.includes('arageek_shortcode_button')) {
      const dataText = 'data-text';
      const { [dataText]: text } = rest;

      return (
        <Center>
          <NextChakraButton
            variant={'outline'}
            rightIcon={<Icon as={ArrowLeft} />}
            href={href}
            target={target}
            rel='noopener noreferrer'
          >
            {text}
          </NextChakraButton>
        </Center>
      );
    }

    if (isFootNote) {
      return (
        <NextChakraLink
          href={'#sources'}
          className={className}
          variant={'unstyled'}
          {...rest}
        >
          <Tag
            className='footnote-number-wrapper'
            size={'sm'}
            backgroundColor='light.500'
            color='light.900'
            rounded={'full'}
            px={0}
            mx={1}
            justifyContent='center'
            sx={{
              counterIncrement: 'css-counter 1'
            }}
            _before={{
              content: 'counter(css-counter)'
            }}
          />
        </NextChakraLink>
      );
    }

    return (
      <NextChakraLink href={href} className={className} {...rest}>
        {domToReact(children, { replace: mainReplacer })}
      </NextChakraLink>
    );
  }

  if (type === 'tag' && name === 'blockquote') {
    const { class: className, href, style, ...rest } = attribs;
    return (
      <chakra.blockquote
        pos='relative'
        py={4}
        px={8}
        sx={{
          '& > p': {
            fontSize: 'lg',
            fontWeight: 'medium',
            fontStyle: 'italic',
            lineHeight: 'normal'
          }
        }}
      >
        <Icon
          pos={'absolute'}
          top={0}
          left={0}
          boxSize={'4em'}
          color='light.500'
          zIndex={-1}
          as={QuoteUp}
          variant='Bold'
        />
        {domToReact(children, { replace: mainReplacer })}
      </chakra.blockquote>
    );
  }

  if (type === 'tag' && name === 'cite') {
    return (
      <ChakraText as={name} fontSize='sm' color={'dark.600'}>
        &mdash; {domToReact(children, { replace: mainReplacer })}
      </ChakraText>
    );
  }

  if (type === 'tag' && name === 'img') {
    const { class: className, src: url, alt, width, height, style } = attribs;
    const image = {
      url,
      alt,
      width: width ?? 624,
      height: height ?? 351
    };

    return (
      <Image
        as='div'
        image={image}
        objectFit={width ? 'contain' : 'cover'}
        sizes={'(min-width: 62em) 49vw, 98vw'}
      />
    );
  }

  if (type === 'tag' && name === 'figcaption') {
    return (
      <ChakraText as={name} fontSize='sm' color={'dark.600'}>
        {domToReact(children, { replace: mainReplacer })}
      </ChakraText>
    );
  }

  if (type === 'tag' && name === 'table') {
    return (
      <TableContainer>
        <Table>{domToReact(children, { replace: mainReplacer })}</Table>
      </TableContainer>
    );
  }

  if (type === 'tag' && name === 'thead') {
    return <Thead>{domToReact(children, { replace: mainReplacer })}</Thead>;
  }
  if (type === 'tag' && name === 'tbody') {
    return <Tbody>{domToReact(children, { replace: mainReplacer })}</Tbody>;
  }
  if (type === 'tag' && name === 'tfoot') {
    return <Tfoot>{domToReact(children, { replace: mainReplacer })}</Tfoot>;
  }
  if (type === 'tag' && name === 'th') {
    return <Th>{domToReact(children, { replace: mainReplacer })}</Th>;
  }
  if (type === 'tag' && name === 'tr') {
    return <Tr>{domToReact(children, { replace: mainReplacer })}</Tr>;
  }
  if (type === 'tag' && name === 'td') {
    return <Td>{domToReact(children, { replace: mainReplacer })}</Td>;
  }

  if (type === 'tag' && name === 'br') {
    return <Box as={name} />;
  }

  if (type === 'tag' && name === 'hr') {
    const mt = attribs?.['data-mt'] || 0;
    const mb = attribs?.['data-mb'] || 0;
    return <Divider mt={mt} mb={mb} />;
  }

  if (type === 'tag' && name === 'audio') {
    const { class: className, style, ...rest } = attribs;
    return (
      <Box as={name} {...rest} w='full' controls>
        {domToReact(children, { replace: mainReplacer })}
      </Box>
    );
  }
  if (type === 'tag' && name === 'video') {
    const { class: className, style, ...rest } = attribs;
    return (
      <AspectRatio ratio={16 / 9}>
        <Box
          as={name}
          {...rest}
          controls
          autopictureinpicture='true'
          preload={'metadata'}
        >
          {domToReact(children, { replace: mainReplacer })}
        </Box>
      </AspectRatio>
    );
  }

  if (type === 'tag' && name === 'iframe') {
    const { src, style, ...rest } = attribs;
    const isYoutube = getYouTubeID(src);
    if (isYoutube) {
      return <YouTube url={src} />;
    }
    return (
      <AspectRatio ratio={16 / 9}>
        <iframe src={src} {...rest} />
      </AspectRatio>
    );
  }

  if (type === 'tag' && name === 'input') {
    return <Input {...attribs} />;
  }

  if (type === 'tag' && name === 'div') {
    const { class: className, style, ...rest } = attribs;

    if (className?.includes('arageek_shortcode_video')) {
      const src = rest?.['data-url'];
      const isYoutube = getYouTubeID(src);
      if (isYoutube) {
        return <YouTube url={src} />;
      }
    }

    if (className?.includes('arageek_shortcode_box')) {
      return (
        <Box p={4} bgColor='light.500'>
          {domToReact(children, { replace: mainReplacer })}
        </Box>
      );
    }

    if (className?.includes('arageek_shortcode_accordion')) {
      return (
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  {rest?.['data-title']}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              {domToReact(children, { replace: mainReplacer })}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    }
    if (className?.includes('arageek_shortcode_biocard')) {
      return (
        <BioCard data={rest}>
          {domToReact(children, { replace: mainReplacer })}
        </BioCard>
      );
    }

    if (className?.includes('arageek_shortcode_toc')) {
      return <TableOfContent />;
    }

    return (
      <div className={className} {...rest}>
        {domToReact(children, { replace: mainReplacer })}
      </div>
    );
  }
  if (type === 'tag' && name === 'span') {
    const { class: className, style, ...rest } = attribs;

    if (className?.includes('arageek_shortcode_star_rating')) {
      const rating = rest?.['data-rating'];
      const maxValue = rest?.['data-max-value'];

      return <StarRating value={Number(rating)} max={Number(maxValue)} />;
    }
  }

  if (type === 'tag') {
    const { class: className, style, ...rest } = attribs;

    return (
      <Box as={name} {...rest}>
        {domToReact(children, { replace: mainReplacer })}
      </Box>
    );
  }
};

export const htmlParser = (
  content: string,
  shouldAddParagraphs = true,
  replacer: HTMLReactParserOptions['replace'] = mainReplacer
) => {
  //TODO: should clean the html for security

  return parse(shouldAddParagraphs ? wpautop(content) : content, {
    replace: replacer
  });
};
