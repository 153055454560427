import { Box } from '@chakra-ui/react';
import { htmlParser } from 'utls';

interface Props {
  content?: string;
}

export const QuestionContent: React.FC<Props> = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <Box fontSize={'sm'} color='dark.600'>
      {htmlParser(content)}
    </Box>
  );
};
