import type { AvatarProps } from '@chakra-ui/react';
import { Avatar, HStack } from '@chakra-ui/react';
import { AddBlogCommentForm } from 'components/forms/AddBlogCommentForm';
import { useAuth } from 'context';
import { useSession } from 'next-auth/react';
import { Answer } from 'types/answers';
import { Comment } from 'types/comments';
import { getFullName } from 'utls';

interface Props {
  answerId: Answer['ID'];
  parentId?: Comment['ID'];
  itemsize: AvatarProps['size'];
  blogId: string;
}

export const AddComment: React.FC<Props> = ({
  answerId,
  itemsize,
  parentId,
  blogId
}) => {
  const { currentUser } = useAuth();
  const { data: session } = useSession();
  const fullName = getFullName(currentUser?.firstname, currentUser?.lastname);

  return (
    <HStack justify={'flex-start'} align='flex-start'>
      <Avatar
        src={currentUser?.avatar_url}
        name={fullName}
        size={itemsize}
        loading='lazy'
      />
      <AddBlogCommentForm
        answerId={answerId}
        parentId={parentId}
        itemsize={itemsize}
        blogId={blogId}
      />
    </HStack>
  );
};
