import { getGAPostParams } from 'utls';

export const pageview = (event, url, route_data) => {
  if (!window.dataLayer) {
    return;
  }

  const fullData = {
    event: event,
    ...getGAPostParams(url, route_data)
  };

  (window?.dataLayer as any)?.push(fullData);
};
