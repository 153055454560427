import { Box, VStack } from '@chakra-ui/react';
import { AddAnswerForm } from 'components/forms/AddAnswerForm';
import { Question } from 'types/questions';

import { CurrentUserCard } from '../users';

export const ADD_ANSWER_BOX_ID = 'add-answer';

export const AddAnswer: React.FC<Pick<Question, 'ID'>> = ({ ID }) => {
  return (
    <Box id={ADD_ANSWER_BOX_ID}>
      <VStack
        spacing={2}
        align='stretch'
        // p={4}
        borderColor='light.500'
        borderWidth={2}
        borderRadius='2xl'
      >
        <CurrentUserCard />
        <AddAnswerForm id={ID} />
      </VStack>
    </Box>
  );
};
