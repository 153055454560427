import deepClean from 'deep-cleaner';

export const removeUndefinedFromObject = o => deepClean(o);

export const filterQueryParamsFromObject = o => {
	const cleanObj = deepClean(o);

	return Object.entries(cleanObj)
		.filter(([key, val]) => !key.startsWith('_') && key !== 'enable_pagination')
		.reduce((result, [key, val]) => {
			result[key] = val;
			return result;
		}, {});
};
