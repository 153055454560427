import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Form, HiddenInput, SubmitButton } from 'components/forms-elements';
import { useToast } from 'hooks';
import { yupValidationRules } from 'utls';

import { useAuth, useQueryKeys } from 'context';
import { FormikConfig } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mutationFn } from 'utls';
import * as Yup from 'yup';
import { useState } from 'react';
import { useTranslation } from 'hooks';

interface Props {
  id: string;
}

export const AddAnswerForm: React.FC<Props> = ({ id }) => {
  const { locale, t } = useTranslation();
  const { token } = useAuth();
  const queryKeys = useQueryKeys();
  const { refetch } = useQuery(queryKeys.answers);
  const toast = useToast();

  const validationRules = yupValidationRules(locale);
  const validationSchema = Yup.object({
    question_id: validationRules?.string,
    content: validationRules?.string.min(10, t('general.validChar_number'))
  });

  const { mutateAsync: mutateAddAnswer } = useMutation(
    params => mutationFn({ name: `add-answer`, params, token }),
    {
      onSuccess: () => {
        refetch();
        toast({
          title: t('general.toastMessages.added'),
          description: '',
          status: 'success'
        });
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      mutateAddAnswer(values);
      resetForm();
    } catch (error) {}
    resetForm();
  };

  const [mentionedUser, setMentionedUser] = useState();

  const [value, setValue] = useState('');
  function fetchUsers(query, callback) {
    if (!query) return;
    fetch(`https://jsonplaceholder.typicode.com/users?q=${query}`, {
      json: true
    })
      .then(res => res.json())

      // Transform the users to what react-mentions expects
      .then(res => res.map(user => ({ display: user.username, id: user.name })))

      .then(callback);
  }

  return (
    <Form
      initialValues={{
        question_id: id,
        content: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <VStack align={'stretch'} spacing={4}>
        <HiddenInput name='question_id' />
        <Box dir='ltr'></Box>
        <Text color={'dark.600'} fontSize='sm'>
          {t('general.toastMessages.arageek_recommendation')}
        </Text>
        <HStack justify={'flex-end'}>
          <SubmitButton>{t('general.toastMessages.add')} </SubmitButton>
        </HStack>
      </VStack>
    </Form>
  );
};
