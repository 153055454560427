import { HStack, Icon, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'hooks';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';
import { User } from 'types/users';

type SocialLink = {
  title: string;
  url: string;
  icon: any;
};

export const SocialLinks: React.FC<
  Pick<
    User,
    'facebook' | 'twitter' | 'instagram' | 'linkedin' | 'youtube' | 'tiktok'
  >
> = ({ facebook, twitter, instagram, linkedin, youtube, tiktok }) => {
  const { t } = useTranslation();
  const items: SocialLink[] = [
    {
      title: t('general.forms.facebook'),
      url: facebook,
      icon: FaFacebookF
    },
    {
      title: t('general.forms.instagram'),
      url: instagram,
      icon: FaInstagram
    },
    {
      title: t('general.forms.tiktok'),
      url: tiktok,
      icon: FaTiktok
    },
    {
      title: t('general.forms.youtube'),
      url: youtube,
      icon: FaYoutube
    },
    {
      title: t('general.forms.twitter'),
      url: twitter,
      icon: FaTwitter
    },
    {
      title: t('general.forms.linkedin'),
      url: linkedin,
      icon: FaLinkedinIn
    }
  ];

  return (
    <HStack as='nav'>
      {items
        .filter(({ url }) => Boolean(url))
        .map(({ icon, url, title }, index) => (
          <IconButton
            as='a'
            icon={<Icon as={icon} />}
            size='sm'
            key={index}
            href={url}
            isRound
            target='_blank'
            rel='noopener noreferrer'
            aria-label={`${t('bio.link')} ${title}`}
          />
        ))}
    </HStack>
  );
};
