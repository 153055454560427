import { HStack, TextareaProps } from '@chakra-ui/react';
import { Textarea as ChakraTextarea, VisuallyHidden } from '@chakra-ui/react';
import { useField } from 'formik';
import type { ChangeEventHandler } from 'react';
import React, { useRef, useState } from 'react';
import { FormControl, SubmitButton } from './';
import { useToast } from 'hooks';
import { useSession } from 'next-auth/react';
import { showLoginModal, useLayoutContext } from 'context/layout';
import { useTranslation } from 'hooks';

interface Props {
  name: string;
  size: TextareaProps['size'];
}

const height = {
  md: 'var(--arageek-sizes-12)',
  sm: 'var(--arageek-sizes-8)'
};

const fontSizes = {
  md: 'var(--arageek-fontSizes-lg)',
  sm: 'var(--arageek-fontSizes-sm)'
};

export const CommentTextarea: React.FC<Props> = ({ name, size }) => {
  const buttonRef = useRef();
  const [field, , { setValue }] = useField(name);
  const [linesCount, setLinesCount] = useState<number>(0);
  const { t } = useTranslation();

  const handleOnKeyUp: ChangeEventHandler<HTMLTextAreaElement> = e => {
    const { value = '' } = event?.target;

    let numberOfLineBreaks = (value.match(/\n/g) || []).length;
    setLinesCount(numberOfLineBreaks);
    setValue(value);
  };

  const minHeight = height?.[size];
  const fontSize = fontSizes?.[size];

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      buttonRef.current.click();
    }
  };
  const { data: session } = useSession();
  const { dispatch } = useLayoutContext();

  const toast = useToast();

  return (
    <>
      <FormControl
        name={name}
        onClick={() => {
          if (!session) {
            toast({
              title: t('general.toastMessages.alert'),
              description: t('general.toastMessages.login_first'),
              status: 'warning',
              duration: 2000
            });
            dispatch(showLoginModal());
          } else {
            return;
          }
        }}
      >
        <ChakraTextarea
          {...field}
          value={field.value ?? ''}
          name={name}
          size={size}
          variant={'outline'}
          _focus={{
            borderColor: 'black'
          }}
          minH={minHeight}
          style={{
            '--number-of-line-breaks': linesCount
          }}
          // min-height + lines x line-height + padding + border
          height={`calc(${minHeight} + var(--number-of-line-breaks) * var(--arageek-lineHeights-short) * ${fontSize})`}
          placeholder={t('general.forms.add_comment')}
          overflow={'hidden'}
          onChange={e => {
            if (session) {
              handleOnKeyUp(e);
            } else {
              return;
            }
          }}
          rounded='lg'
          backgroundColor='white'
          onKeyDown={onKeyDown}
        />
        <VisuallyHidden>
          <button ref={buttonRef} type='submit'>
            {t('general.toastMessages.add')}
          </button>
        </VisuallyHidden>
      </FormControl>
      <HStack justify={'flex-end'} mt='4'>
        {field.value && (
          <SubmitButton
            ref={buttonRef}
            onClick={() => {
              if (!session?.accessToken) {
                toast({
                  title: t('general.toastMessages.alert'),
                  description: t('general.toastMessages.login_first'),
                  status: 'warning',
                  duration: 2000
                });
                dispatch(showLoginModal());
              }
            }}
          >
            {t('general.toastMessages.add')}
          </SubmitButton>
        )}
      </HStack>
    </>
  );
};
