import type { BoxProps } from '@chakra-ui/react';
import { AspectRatio, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export const EyesSvg: React.FC<BoxProps> = (props) => {
  return (
    <Box w={{ base: 36 }} {...props}>
      <AspectRatio ratio={78 / 57}>
        <Box
          as='svg'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 78 57'
        >
          <motion.g
            animate={{ scaleY: [1, 0, 1] }}
            transition={{
              repeat: Infinity,
              repeatDelay: 4,
              duration: 0.1,
            }}
          >
            <motion.path
              d='M76.5383 28.1192C76.5383 35.791 74.4339 42.7131 71.0588 47.7024C67.6817 52.6947 63.0715 55.7054 58.0465 55.7054C53.0214 55.7054 48.4113 52.6947 45.0342 47.7024C41.6591 42.7131 39.5546 35.791 39.5546 28.1192C39.5546 20.4475 41.6591 13.5254 45.0342 8.53609C48.4113 3.54383 53.0214 0.533135 58.0465 0.533135C63.0715 0.533135 67.6817 3.54383 71.0588 8.53609C74.4339 13.5254 76.5383 20.4475 76.5383 28.1192Z'
              fill='white'
              stroke='black'
              strokeWidth='1.04674'
            />
            <motion.g
              animate={{ x: 12 }}
              transition={{
                repeat: Infinity,
                repeatType: 'reverse',
                repeatDelay: 5,
              }}
            >
              <motion.circle
                cx='51.0191'
                cy='31.0128'
                r='11.4645'
                fill='white'
                stroke='black'
                strokeWidth='1.04674'
              />
              <motion.circle
                cx='51.0207'
                cy='31.0129'
                r='8.1575'
                fill='black'
                stroke='black'
                strokeWidth='1.04674'
              />
              <motion.path
                d='M46.5021 39.4335C46.0831 40.2144 45.1016 40.5165 44.3888 39.9899C43.2694 39.163 42.3107 38.1312 41.5661 36.9443C40.5361 35.3027 39.9514 33.4211 39.8694 31.4849C39.7874 29.5486 40.211 27.6244 41.0985 25.9015C41.986 24.1787 43.3068 22.7166 44.9309 21.6592C46.555 20.6018 48.4265 19.9855 50.3611 19.8711C52.2957 19.7566 54.2268 20.1478 55.9643 21.0062C57.7019 21.8647 59.1859 23.1608 60.2704 24.7669C61.0544 25.928 61.6101 27.2223 61.9134 28.5805C62.1065 29.4454 61.4331 30.2208 60.5494 30.288C59.6657 30.3552 58.9106 29.685 58.6676 28.8327C58.4383 28.0282 58.0825 27.2617 57.6106 26.5628C56.838 25.4185 55.7807 24.4952 54.5428 23.8835C53.3049 23.2719 51.9291 22.9932 50.5507 23.0748C49.1724 23.1564 47.8391 23.5954 46.6819 24.3488C45.5248 25.1021 44.5838 26.1438 43.9515 27.3712C43.3192 28.5987 43.0174 29.9696 43.0758 31.3491C43.1342 32.7286 43.5509 34.0691 44.2847 35.2387C44.7328 35.953 45.2898 36.5885 45.9327 37.1238C46.6138 37.6908 46.9212 38.6526 46.5021 39.4335Z'
                fill='#515151'
                fillOpacity='0.8'
              />
              <motion.circle
                cx='44.8201'
                cy='24.8123'
                r='2.48025'
                fill='white'
              />
            </motion.g>
          </motion.g>
          <motion.g
            animate={{ scaleY: [1, 0, 1] }}
            transition={{
              repeat: Infinity,
              repeatDelay: 4,
              duration: 0.1,
            }}
          >
            <motion.path
              d='M38.5032 28.1192C38.5032 35.791 36.3987 42.7131 33.0236 47.7024C29.6465 52.6947 25.0364 55.7054 20.0113 55.7054C14.9863 55.7054 10.3761 52.6947 6.99903 47.7024C3.62393 42.7131 1.51946 35.791 1.51946 28.1192C1.51946 20.4475 3.62393 13.5254 6.99903 8.53609C10.3761 3.54383 14.9863 0.533135 20.0113 0.533135C25.0364 0.533135 29.6465 3.54383 33.0236 8.53609C36.3987 13.5254 38.5032 20.4475 38.5032 28.1192Z'
              fill='white'
              stroke='black'
              strokeWidth='1.04674'
            />
            <motion.g
              animate={{ x: 12 }}
              transition={{
                repeat: Infinity,
                repeatType: 'reverse',
                repeatDelay: 5,
              }}
            >
              <motion.circle
                cx='12.984'
                cy='31.0128'
                r='11.4645'
                fill='white'
                stroke='black'
                strokeWidth='1.04674'
              />
              <motion.circle
                cx='12.9816'
                cy='31.0129'
                r='8.1575'
                fill='black'
                stroke='black'
                strokeWidth='1.04674'
              />
              <motion.path
                d='M8.45917 39.4335C8.04015 40.2144 7.05861 40.5165 6.34579 39.9899C5.22465 39.1617 4.26464 38.1278 3.51954 36.9386C2.48935 35.2944 1.90559 33.4101 1.82584 31.4714C1.74609 29.5328 2.17311 27.6068 3.06477 25.8836C3.95643 24.1603 5.28193 22.6992 6.91052 21.6445C8.53911 20.5898 10.4145 19.9779 12.3518 19.8691C14.289 19.7603 16.2211 20.1584 17.9576 21.0242C19.694 21.8899 21.1747 23.1934 22.2537 24.806C23.0341 25.9723 23.5848 27.2712 23.882 28.6331C24.071 29.4989 23.3938 30.2711 22.5098 30.334C21.6258 30.3969 20.8739 29.7231 20.6351 28.8696C20.4092 28.0625 20.0562 27.2929 19.5864 26.5907C18.8177 25.4418 17.7627 24.5131 16.5256 23.8963C15.2884 23.2795 13.9119 22.9959 12.5317 23.0734C11.1515 23.1509 9.81536 23.5869 8.65506 24.3383C7.49477 25.0897 6.55041 26.1307 5.91514 27.3584C5.27987 28.5862 4.97564 29.9583 5.03246 31.3395C5.08928 32.7207 5.50518 34.0632 6.23914 35.2347C6.68772 35.9506 7.24562 36.5875 7.88974 37.1238C8.57082 37.6908 8.87818 38.6526 8.45917 39.4335Z'
                fill='#515151'
                fillOpacity='0.8'
              />
              <motion.circle
                cx='6.78103'
                cy='24.8123'
                r='2.48025'
                fill='white'
              />
            </motion.g>
          </motion.g>
        </Box>
      </AspectRatio>
    </Box>
  );
};
