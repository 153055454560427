import { getItemsData, removeUndefinedFromObject } from 'utls';

export const getWikiCategories = (attrs: Record<string, unknown>) => {
  const { data } = attrs;
  const { boxes } = data;
  return Array(boxes)
    .fill('')
    .map((_, i) => {
      const ids = data?.[`boxes_${i}_encyclopedia_cat_terms`];

      if (Array.isArray(ids)) {
        return {
          icon: data?.[`boxes_${i}_icon_code`],
          title: data?.[`boxes_${i}_title`],
          ids
        };
      }

      return null;
    })
    .filter(Boolean)
    .map(async item => {
      const { ids, ...rest } = item;

      const params = removeUndefinedFromObject({
        per_page: ids.length,
        include: ids.filter(Boolean).join(','),
        orderby: 'include'
      });

      return {
        ...rest,
        categories: await getItemsData('encyclopedia_cat', params)
      };
    });
};
