import { ChakraProvider } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import arTheme from 'configs/chakraThemes/arChakraTheme';
import enTheme from 'configs/chakraThemes/enChakraTheme';
import { AuthProvider, PageProvider } from 'context';
import NextNProgress from 'nextjs-progressbar';
import { defaultQueryFn } from 'utls';
import { GoogleOAuthProvider } from '@react-oauth/google';

import dynamic from 'next/dynamic';
import { LayoutContextProvider } from './layout';
const CommentsProvider = dynamic(() => import('./CommentsProvider'));
const SiteConfigProvider = dynamic(() => import('./SiteConfigProvider'));
const RTLProvider = dynamic(() => import('./RTLProvider'));
import Router from 'next/router';

import { useEffect } from 'react';

import {
  IzootoScripts,
  GoogleAnalytics,
  PreflightCSS,
  GoogleAnalytics2
} from 'components/system';
// import { YandexMetricaProvider } from 'next-yandex-metrica';

const { ToastContainer } = createStandaloneToast();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn
    }
  }
});

const routeChangeStart = nextUrl => {
  const prevUrl = location.pathname;
  const prevLocale = prevUrl.startsWith('/en') ? 'en' : 'ar';

  const nextLocale = nextUrl.startsWith('/en') ? 'en' : 'ar';
  if (prevLocale !== nextLocale) {
    console.log('here');
    window.location.replace(nextUrl);
  }
};
const routeChangeEnd = nextUrl => {};
const routeChangeError = () => {
  console.log('here3');
};

export const Providers: React.FC = ({ pageProps, children }: any) => {
  const isEnglish = pageProps?.route_data?.language !== 'en';

  useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeError);
    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeEnd);
      Router.events.off('routeChangeError', routeChangeError);
    };
  }, []);

  return (
    <>
      <GoogleAnalytics />
      <GoogleAnalytics2 />
      <IzootoScripts />
      <PreflightCSS />
      <NextNProgress
        color='#000'
        startPosition={0.3}
        stopDelayMs={200}
        height={1}
      />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ChakraProvider theme={isEnglish ? arTheme : enTheme}>
          <LayoutContextProvider>
            <AuthProvider>
              <RTLProvider>
                <CommentsProvider>
                  <PageProvider initialPageProps={pageProps}>
                    <SiteConfigProvider>
                      <GoogleOAuthProvider
                        clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
                      >
                        {/* <YandexMetricaProvider
                          tagID={94501063}
                          initParameters={{
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true
                          }}
                        >
                        
                        </YandexMetricaProvider> */}
                          {children}
                      </GoogleOAuthProvider>
                    </SiteConfigProvider>
                  </PageProvider>
                </CommentsProvider>
              </RTLProvider>
            </AuthProvider>
          </LayoutContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
      <ToastContainer />
    </>
  );
};
