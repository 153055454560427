import { AvatarProps, Box, Collapse } from '@chakra-ui/react';
import {
  Avatar,
  HStack,
  Spacer,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import {
  AddCommentButton,
  DownVoteButton,
  UpVoteButton
} from 'components/questions/shared';

import { ActionsMenu } from 'components/shared';
import { ReportMenu } from 'components/shared/ReportMenu';
import { useCurrentUser } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { Answer } from 'types/answers';
import { Comment } from 'types/comments';

import { getFullName, getRelativeDate, htmlParser } from 'utls';
import { AddComment } from './AddComment';
import { CardSubComment } from './CardSubComment';
import { useSession } from 'next-auth/react';
import { useAuth } from 'context';

interface Props extends Comment {
  avatarSize: AvatarProps['size'];
  answerId: Answer['ID'];
  replies?: any;
  commentsRefetch?: any;
}

export const SingleComment: React.FC<Props> = ({
  answerId,
  comments,
  date,
  content,
  author,
  avatarSize,
  votes,
  current_vote,
  parent_id,
  replies,
  commentsRefetch,
  blogId,
  author_name,
  author_avatar_urls,
  id
}) => {
  const { data: session } = useSession();
  const { isOpen: isAddCommentOpen, onOpen: onAddCommentOpen } =
    useDisclosure();
  const { isOpen: isOpenReplies, onOpen: onRepliesOpen } = useDisclosure();

  // const { avatar_url, firstname, lastname, slug: authorSlug } = author;

  // const { slug: currentUserSlug } = currentUser;

  // const fullName = useMemo(
  //   () => getFullName(firstname, lastname),
  //   [firstname, lastname]
  // );
  const relativeDate = useMemo(() => getRelativeDate(date), [date]);

  // const [votesData, setVotes] = useState({
  //   upvotes: 0,
  //   downvotes: 0,
  //   currentVote: ''
  // });
  // const callBack = data => {
  //   setVotes({ ...data?.votes, currentVote: data?.actionType });
  // };

  // useEffect(() => {
  //   if (votes) setVotes({ ...votes, currentVote: current_vote });
  // }, [votes]);

  // const parsed = content?.rendered ? htmlParser(content?.rendered) : '';

  return (
    <HStack w='full' justify={'flex-start'} align='flex-start'>
      <Avatar
        src={author_avatar_urls['400'] ?? author_avatar_urls['250']}
        name={author_name}
        size={avatarSize}
        loading='lazy'
      />
      <VStack
        w='full'
        align={'stretch'}
        p={3}
        backgroundColor='#F1EFE2'
        rounded='lg'
      >
        <Text fontSize='md'>
          <Text as='span' fontWeight={'bold'}>
            {author_name}
          </Text>{' '}
          .{' '}
          <Text as='span' color='gray.400'>
            {relativeDate}
          </Text>
        </Text>

        <Text overflowWrap={'anywhere'}>{htmlParser(content?.rendered)}</Text>
        <ActionBar
          {...{
            id,
            onAddCommentOpen,
            onRepliesOpen,
            replies,
            commentsRefetch,
            content,
            currentUser: session?.user?.id,
            author

            // current_vote: votesData?.currentVote,
            // votes: votesData
          }}
          // dataCallBack={callBack}
        />

        <Collapse in={isOpenReplies}>
          <VStack spacing={4} align='stretch'>
            {replies?.map((reply, index) => {
              return (
                <Box key={index} pl='2' borderLeft={'3px solid #fff'}>
                  <CardSubComment
                    {...reply}
                    commentsRefetch={commentsRefetch}
                  />
                </Box>
              );
            })}
            <AddComment
              parentId={id}
              answerId={answerId}
              itemsize={avatarSize}
              blogId={blogId}
            />
          </VStack>
        </Collapse>
        {replies?.length === 0 && isAddCommentOpen && (
          <AddComment
            parentId={id}
            answerId={answerId}
            itemsize={avatarSize}
            blogId={blogId}
          />
        )}
      </VStack>
    </HStack>
  );
};

const ActionBar: React.FC<
  { onAddCommentOpen: () => void; onRepliesOpen: () => void } & Pick<
    Props,
    'ID' | 'level' | 'votes' | 'current_vote' | 'replies' | 'commentsRefetch'
  >
> = ({
  id,
  // level,
  onAddCommentOpen,
  votes,
  current_vote,
  onRepliesOpen,
  replies,
  commentsRefetch,

  content,
  currentUser,

  author,
  dataCallBack
}) => {
  // const { upvotes, downvotes } = votes;

  const contentHtml = content?.rendered?.replace(/<[^>]+>/g, '');

  const [editAnswer, setEditAnswer] = useState(false);
  return (
    <HStack align={'center'}>
      {/* <UpVoteButton
        refType='comment'
        refId={ID}
        count={upvotes}
        currentVote={current_vote}
        size={'sm'}
        callback={dataCallBack}
      />
      <Box color='#999999' fontSize='md' fontWeight={'bold'}>
        &#183;
      </Box>
      <DownVoteButton
        refType='comment'
        refId={ID}
        count={downvotes}
        currentVote={current_vote}
        size={'sm'}
        callback={dataCallBack}
      /> */}
      {/* <Box color='#999999' fontSize='md' fontWeight={'bold'}>
        &#183;
      </Box> */}

      <AddCommentButton
        onClick={replies?.length === 0 ? onAddCommentOpen : onRepliesOpen}
        size={'sm'}
        count={replies?.length}
      />

      <Spacer />

      {/* {author === currentUser ? (
        <ActionsMenu
          type='comment'
          id={id}
          editAnswer={editAnswer}
          setEditAnswer={setEditAnswer}
          content={contentHtml}
          setIsEditQuestion={() => {}}
          setIsOpenAskModal={() => {}}
          commentsRefetch={commentsRefetch}
        />
      ) : (
        ''
      )} */}
    </HStack>
  );
};
