import type { IconButtonProps } from '@chakra-ui/react';
import {
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react';
import { useTranslation } from 'hooks';
import { Send2 } from 'iconsax-react';
import {
  FaFacebook,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp
} from 'react-icons/fa';

interface Props {
  url: string;
  title: string;
}

const sharedProps: IconButtonProps = {
  as: 'a',
  size: 'sm',
  variant: 'ghost',
  target: '_blank',
  rel: 'noopener noreferrer'
};

export const ShareMenu: React.FC<Props> = ({ url, title }) => {
  const { t } = useTranslation();
  const HOSTNAME =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';

  return (
    <Popover isLazy direction='rtl'>
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={Send2} color='dark.600' />}
          bg='#F1EFE2'
          aria-label={t('bio.share')}
          variant={'ghost'}
          size='md'
        />
      </PopoverTrigger>
      <PopoverContent rounded='full' w='auto'>
        <PopoverArrow />
        <PopoverBody p={0}>
          <HStack spacing={1}>
            <IconButton
              {...sharedProps}
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                HOSTNAME + url
              }`}
              icon={<Icon as={FaFacebook} />}
              aria-label={t('bio.share_on_facebook')}
              colorScheme={'facebook'}
            />
            <IconButton
              {...sharedProps}
              href={`https://twitter.com/intent/tweet?text=${title} ${
                HOSTNAME + url
              }`}
              icon={<Icon as={FaTwitter} />}
              aria-label={t('bio.share_on_twitter')}
              colorScheme={'twitter'}
            />
            <IconButton
              {...sharedProps}
              href={`https://www.linkedin.com/sharing/share-offsite/?&url=${
                HOSTNAME + url
              }`}
              icon={<Icon as={FaLinkedinIn} />}
              aria-label={t('bio.share_on_linkedin')}
              colorScheme={'linkedin'}
            />
            <IconButton
              {...sharedProps}
              href={`https://api.whatsapp.com/send?text=${title} ${
                HOSTNAME + url
              }`}
              icon={<Icon as={FaWhatsapp} />}
              aria-label={t('bio.share_on_whatsapp')}
              colorScheme={'whatsapp'}
            />
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
