import { createContext, useContext, useReducer } from 'react';

const initialState = {
  loginModalShow: false,
  registerModalShow: false
};

const actionTypes = {
  SHOW_LOGIN_MODAL: 'SHOW_LOGIN_MODAL',
  HIDE_LOGIN_MODAL: 'HIDE_LOGIN_MODAL',

  SHOW_REGISTER_MODAL: 'SHOW_REGISTER_MODAL',
  HIDE_REGISTER_MODAL: 'HIDE_REGISTER_MODAL'
};

// action creators

const showLoginModal = () => ({ type: actionTypes.SHOW_LOGIN_MODAL });
const hideLoginModal = () => ({ type: actionTypes.HIDE_LOGIN_MODAL });

const showRegisterModal = () => ({ type: actionTypes.SHOW_REGISTER_MODAL });
const hideRegisterModal = () => ({ type: actionTypes.HIDE_REGISTER_MODAL });

const LayoutContext = createContext({});

const layoutReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOGIN_MODAL:
      return { ...state, loginModalShow: true };

    case actionTypes.HIDE_LOGIN_MODAL:
      return { ...state, loginModalShow: false };

    case actionTypes.SHOW_REGISTER_MODAL:
      return { ...state, registerModalShow: true };

    case actionTypes.HIDE_REGISTER_MODAL:
      return { ...state, registerModalShow: false };

    default:
      return state;
  }
};

const LayoutContextProvider = ({ children }) => {
  // you can add init function as third arg to useReducer
  const [state, dispatch] = useReducer(layoutReducer, initialState);

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </LayoutContext.Provider>
  );
};

const useLayoutContext = () => {
  const { state, dispatch } = useContext(LayoutContext);

  return { state, dispatch };
};

export {
  LayoutContextProvider,
  useLayoutContext,
  showLoginModal,
  hideLoginModal,
  showRegisterModal,
  hideRegisterModal
};
