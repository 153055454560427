import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Heading
} from '@chakra-ui/react';

import RequestForm from 'components/forms/RequestForm';
import { useTranslation } from 'hooks';

export const RegisterModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const onClose = () => setIsOpen(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody py='8' px='12'>
          <VStack align='stretch' spacing={8}>
            <VStack align={'stretch'} spacing='4' textAlign='center'>
              <Heading as='h3' fontSize={'h2'}>
                {' '}
                {t('bio.create_account')}
              </Heading>
              <Text color='dark.600'>{t('bio.approve_email')} </Text>
            </VStack>

            <RequestForm
              type='register'
              btnText={t('bio.the_next')}
              btnProps={{
                colorScheme: 'dark'
              }}
            ></RequestForm>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
