import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from 'context';
import { useToast, useTranslation } from 'hooks';
import { useSession } from 'next-auth/react';

import { mutationFn } from 'utls';

export const useGetBookmarks = () => {
  const { token } = useAuth();

  const { data, refetch, isLoading } = useQuery([
    'get-bookmarks',
    {
      count: 100,
      namespace: 'arageek/v1'
    },
    token
  ]);

  return {
    data: data?.data,
    refetch,
    isLoading
  };
};

export const useBookmark = (id, callBack) => {
  const { t } = useTranslation();
  const { data: session } = useSession();
  const toast = useToast();
  const { mutateAsync: mutateBookMark, isLoading } = useMutation(
    (params: any) =>
      mutationFn({
        name: params?.type === 'add' ? `add-bookmark` : `delete-bookmark`,
        params: { object_id: id, type: params?.type },
        token: session?.accessToken,
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: ({ data, params }: any) => {
        toast({
          title: t('bio.seccessful_op'),
          description:
            params?.type === 'add'
              ? t('bio.added_to_my_magazine')
              : t('bio.deleted_from_my_magazine'),
          status: 'success'
        });
        callBack({
          ...data,
          isBookMarked: params?.type === 'add' ? true : false
        });
      },
      onError: error => {
        console.log(error, 'error');
      }
    }
  );

  const { mutateAsync: mutateIsBookMarked, isLoading: isBookedLoading } =
    useMutation(
      (params: any) =>
        mutationFn({
          name: 'is-bookmarked',
          params: { object_id: id },
          token: session?.accessToken,
          namespace: 'arageek/v1'
        }),
      {
        onSuccess: ({ data }: any) => {
          callBack(data);
        },
        onError: error => {
          console.log(error, 'errorerror');
        }
      }
    );

  return {
    mutateIsBookMarked,
    mutateBookMark,
    isBookedLoading,
    isLoading
  };
};
