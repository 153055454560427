import { get } from 'lodash';
import data from 'assets/language/data.json';
import { useRouteData } from './useRouteData';
import { useRouter } from 'next/router';

export const useTranslation = () => {
  const routeData = useRouteData();
  const { asPath } = useRouter();
  const xlocale = asPath.startsWith('/en') ? 'en' : 'ar';
  const locale = routeData?.language || xlocale || 'ar';
  const t = (path: string) => {
    return get(data, `${path}.${locale}`) ?? path;
  };

  return { t, locale };
};
