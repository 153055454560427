import { VStack } from '@chakra-ui/react';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';
import { useSession } from 'next-auth/react';

import { useToast, useTranslation } from 'hooks';
import { yupValidationRules } from 'utls';
import {
  Form,
  HiddenInput,
  SubmitButton,
  Textarea
} from 'components/forms-elements';

interface Props {
  id: string;
  content?: string;
  editAnswer?: boolean;
  setEditAnswer?: (editAnswer) => void;
  refetch?: () => void;
}

export const EditAnswerForm: React.FC<Props> = ({ id, content, refetch }) => {
  const toast = useToast();
  const { data: session } = useSession();
  const { locale, t } = useTranslation();

  const validationRules = yupValidationRules(locale);
  const validationSchema = Yup.object({
    id: validationRules?.stringOptional?.optional(),
    content: validationRules?.string?.min(15, t('general.at_least_15_char'))
  });

  const headers = {
    Authorization: `Bearer ${session?.accessToken}`
  };

  const { mutateAsync: mutateEditComment } = useMutation(
    (params: any) => {
      return axios.post(
        `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/wp-json/wp/v2/comments/${id}`,
        {},
        {
          headers: headers
        }
      );
    },
    {
      onSuccess: ({ data }) => {
        toast({
          title: t('bio.seccessful_op'),
          description: t('general.toastMessages.modified_seccessfully'),
          status: 'success'
        });
        refetch();
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      mutateEditComment(values);
      resetForm();
    } catch (error) {
      resetForm();
    }
  };

  return (
    <Form
      initialValues={{
        id: id,
        content: content
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <VStack align={'stretch'} spacing={4}>
        <HiddenInput name='id' />
        <Textarea
          name='content'
          label={t('general.toastMessages.answer_text')}
          height={40}
        />
      </VStack>
      <VStack align={'stretch'} spacing={8} mt='10'>
        <SubmitButton>{t('general.toastMessages.edit_answer')}</SubmitButton>
      </VStack>
    </Form>
  );
};
