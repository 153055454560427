import { VStack } from '@chakra-ui/react';

import { useMutation } from '@tanstack/react-query';
import { Form, Input, SubmitButton, Textarea } from 'components/forms-elements';

import { showLoginModal, useLayoutContext } from 'context/layout';
import { FormikConfig } from 'formik';
import { useToast, useTranslation } from 'hooks';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSession } from 'next-auth/react';
import { mutationFn, yupValidationRules } from 'utls';
import * as Yup from 'yup';

export const AddCommentForm: React.FC = ({ id, callback, onClose }) => {
  const toast = useToast();
  const { data: session } = useSession();
  const { dispatch } = useLayoutContext();
  const { locale, t } = useTranslation();

  const validationRules = yupValidationRules(locale);
  const validationSchema = Yup.object({
    author_name: validationRules.string,
    author_email: validationRules.email,
    content: validationRules.string.min(50, t('general.validChar_number'))
  });

  const { mutateAsync: mutateAddComment } = useMutation(
    params => mutationFn({ name: `comments`, params }),
    {
      onSuccess: ({ data, params }) => {
        if (data.status === 'hold') {
          toast({
            title: t('general.toastMessages.thanks'),
            description: t('general.toastMessages.comment_under_review'),
            status: 'warning'
          });
          callback?.();
        } else {
          toast({
            title: t('general.toastMessages.thanks'),
            description: t('general.toastMessages.comment_added_successfully')
          });
          callback?.();
        }
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      if (session?.accessToken) {
        await mutateAddComment({ post: id, ...values });
        resetForm();
      } else {
        toast({
          title: t('general.toastMessages.alert'),
          description: t('general.toastMessages.login_first'),
          status: 'warning',
          duration: 2000
        });
        onClose();
        dispatch(showLoginModal());
      }
    } catch (error) {
      resetForm();
    }
  };

  const currenUser = useCurrentUser();
  const { fullname, email } = currenUser;

  return (
    <Form
      initialValues={{
        author_name: currenUser?.fullname ? `${fullname}` : '',
        author_email: currenUser?.email ? `${email}` : '',
        content: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      hasDevTools={true}
      enableReinitialize
    >
      <VStack align={'stretch'} spacing={8}>
        <VStack align={'stretch'} spacing={4}>
          <Input name='author_name' label={t('general.forms.name')} />
          <Input name='author_email' label={t('general.forms.email')} />
          <Textarea name='content' label={t('general.forms.kind_comment')} />
        </VStack>
        <VStack align={'stretch'} spacing={4}>
          <SubmitButton>{t('general.forms.send')}</SubmitButton>
        </VStack>
      </VStack>
    </Form>
  );
};
