import { AvatarProps, HStack } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { CommentTextarea, Form, HiddenInput } from 'components/forms-elements';
import { FormikConfig } from 'formik';
import { useComments, useTranslation } from 'hooks';
import { yupValidationRules } from 'utls';
import * as Yup from 'yup';

interface Props {
  answerId: string;
  parentId?: string;
  itemsize: AvatarProps['size'];
  blogId: string;
}

export const AddBlogCommentForm: React.FC<Props> = ({
  answerId,
  parentId,
  itemsize,
  blogId
}) => {
  const { locale } = useTranslation();

  const validationRules = yupValidationRules(locale);

  const validationSchema = Yup.object({
    parent_comment_id: validationRules?.stringOptional?.optional(),
    object_id: validationRules?.stringOptional?.optional(),
    content: validationRules?.stringOptional
  });

  const { mutateAddComment } = useComments(answerId);
  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      if (!values.parent) {
        delete values.parent;
      }
      await mutateAddComment(values);
    } catch (error) {}
    resetForm();
  };

  return (
    <Box w='full'>
      <Form
        initialValues={{
          parent: parentId,
          post: blogId,
          content: ''
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <HiddenInput name='object_id' />
        <CommentTextarea name='content' size={itemsize} />
      </Form>
    </Box>
  );
};
