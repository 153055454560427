import { Box, Container, Text, VStack } from '@chakra-ui/react';
import { Logo } from 'assets';
import { SocialLinks } from './components';
import { useTranslation } from 'hooks';

const currentYear = new Date().getFullYear();

interface Props {
  variant?: 'default' | 'basic';
  SocialMenu?: any;
}

const Footer: React.FC<Props> = ({ variant = 'default' }) => {
  const { t } = useTranslation();
  return (
    <Box as='footer' bgColor='black' color='white' py={12} textAlign={'center'}>
      <Container centerContent>
        <VStack spacing={4}>
          <Logo color='white' hasAlwaysFullLogo />
          {variant === 'default' && <SocialLinks />}
          <Text color='gray.100'>
            {t('general.footer.company_rights')} &copy; {currentYear}{' '}
            {t('general.footer.company_name')}
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};
export default Footer;
