import { createIcon } from '@chakra-ui/icon';

export const HeadingDoodle = createIcon({
	displayName: 'HeadingDoodle',
	path: (
		<g fill='currentColor'>
			<path d='M8.026 21.463c-2.005-.562-4.104-.846-6.14-1.106-.44-.054-.847.25-.878.678-.063.43.25.821.658.876 1.974.25 4.01.518 5.922 1.06a.796.796 0 0 0 .971-.54.78.78 0 0 0-.533-.968ZM14.104 13.488c-3.227-3.292-6.861-6.16-10.026-9.54a.771.771 0 0 0-1.096-.039.77.77 0 0 0-.031 1.107c3.164 3.39 6.798 6.268 10.025 9.57a.808.808 0 0 0 1.128.01c.282-.303.314-.8 0-1.108ZM20.152.826l.281 5.653c0 .431.377.764.815.744.439-.022.752-.39.752-.821L21.718.74a.803.803 0 0 0-.846-.739.78.78 0 0 0-.72.825Z' />
		</g>
	),
	viewBox: '0 0 23 23'
});
