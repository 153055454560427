import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCookie } from 'react-use';
import { signOut, useSession } from 'next-auth/react';

import { useToast, useTranslation } from 'hooks';
import { mutationFn, setCookieFn, USER_TOKEN_KEY } from 'utls';
import axios from 'redaxios';
import { hideLoginModal, useLayoutContext } from './layout';
export const AuthContext = createContext({});

const LOGIN_URL = '/login';
const VALIDATE_LOGIN_URL = '/login/validate';
const VALIDATE_REGISTER_URL = '/register/validate';
const REGISTER_CREATE_PROFILE = '/login/create-profile';
const REGISTER_SELECT_INTERESTS = '/register/select-interests';
const HOME_PAGE = '/';

export const AuthProvider: React.FC = ({ children }: any) => {
  const { t } = useTranslation();
  const { data: session }: any = useSession();
  const toast = useToast();
  const { push } = useRouter();
  const [authEmail, setAuthEmail, removeAuthEmail] = useCookie(
    'arageek-magazine-auth-email'
  );
  const { dispatch, state } = useLayoutContext();

  const [currentUser, setCurrentUser] = useState({});
  const [token, setToken, removeToken] = useCookie('next-auth.session-token');
  const [tokenValue, setTokenValue] = useState('');
  const getUser = async () => {
    const { data } = await axios.get(
      `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/wp-json/arageek/v1/get-user`,
      {
        headers: {
          ...(session?.accessToken && {
            Authorization: `Bearer ${session?.accessToken}`
          })
        }
      }
    );

    return setCurrentUser(data.data);
  };

  const {
    isError,
    isLoading,
    data: userData,
    refetch: refetchUser
  } = useQuery(['get-user', getUser], {
    enabled: Boolean(session?.accessToken)
  });

  useEffect(() => {
    if (session?.accessToken) getUser();
  }, [session?.accessToken]);

  const { mutateAsync: mutateRequestAuth } = useMutation(
    params =>
      mutationFn({
        name: params?.type === 'login' ? `request_auth` : `register`,
        params,
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: ({ data }) => {
        setAuthEmail(params?.email);

        dispatch(hideLoginModal());
        if (
          !params.resend &&
          data?.register_type === 'existing' &&
          params.type === 'register'
        ) {
          toast({
            title: t('general.toastMessages.already_exists'),
            description: '',
            status: 'warning'
          });
        }
        if (data?.register_type !== 'existing' && params.type === 'register') {
          setAuthEmail(params?.email);

          push(`${VALIDATE_LOGIN_URL}?url=${params.backUrl}`);
          toast({
            title: t('general.toastMessages.confirmation_code'),
            description: '',
            status: 'success'
          });
        }

        if (!params.resend && params.type === 'login') {
          setAuthEmail(params?.email);
          push(`${VALIDATE_LOGIN_URL}?url=${params.backUrl}`);
          toast({
            title: t('general.toastMessages.confirmation_code'),
            description: '',
            status: 'success'
          });
        }
        if (params.resend) {
          toast({
            title: t('general.toastMessages.confirmation_code'),
            description: '',
            status: 'success'
          });
        }
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.email_not_found'),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateVerifyAuth } = useMutation(
    data =>
      mutationFn({
        name: `verify_auth`,
        params: { email: authEmail, ...data },
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: ({ data }) => {
        const { token } = data?.auth;
        setCookieFn(USER_TOKEN_KEY, token, 4);

        sessionStorage.setItem('arageek-token', token);
        removeAuthEmail();
        if (!data?.auth?.firstName || !data?.auth?.lastName) {
          push(REGISTER_CREATE_PROFILE);
        } else {
          push(HOME_PAGE);
        }
      },
      onError: ({ data }) => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.code_error'),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateUpdateUser } = useMutation(
    params =>
      mutationFn({
        name: `edit-user`,
        params,
        namespace: 'arageek/v1',
        token: tokenValue
      }),
    {
      onSuccess: ({ data, params }) => {
        push(HOME_PAGE);
      },
      onError: ({ data }) => {
        toast({
          title: t('general.toastMessages.error'),
          description:
            data?.message === 'Username already exists'
              ? t('general.toastMessages.username_error')
              : t('general.toastMessages.try_again'),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateGoogleAuth } = useMutation(
    params =>
      mutationFn({
        name: `google-login`,
        params: params,
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: data => {
        const token = String(data.data.auth.token);
        setCookieFn(USER_TOKEN_KEY, token, 4);
        sessionStorage.setItem('arageek-token', token);
        dispatch(hideLoginModal());
        push(HOME_PAGE);
      }
    }
  );

  const { mutateAsync: mutateFacebookAuth } = useMutation(
    ({ access_token }) =>
      mutationFn({
        name: `facebook-login`,
        params: { access_token },
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: data => {
        const token = String(data.data.auth.token);
        setCookieFn(USER_TOKEN_KEY, token, 4);
        sessionStorage.setItem('arageek-token', token);
        dispatch(hideLoginModal());
        push(HOME_PAGE);
      }
    }
  );

  const goToLoginPage = () => {
    window.location.href = '/';
  };

  const mutateLogout = async () => {
    // removeToken();
    await signOut();
    toast({
      title: t('general.toastMessages.signed_out_successfully'),
      description: '',
      status: 'success'
    });
    goToLoginPage();
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        mutateRequestAuth,
        mutateVerifyAuth,
        mutateLogout,
        mutateGoogleAuth,
        mutateFacebookAuth,
        currentUser,
        mutateUpdateUser,
        isLoggedIn: Boolean(tokenValue)
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
