import {
  Flex,
  Avatar,
  Text,
  VStack,
  Box,
  Spacer,
  HStack
} from '@chakra-ui/react';
import { DownVoteButton, UpVoteButton } from 'components/questions/shared';
import { ActionsMenu } from 'components/shared';
import { ReportMenu } from 'components/shared/ReportMenu';
import { useSession } from 'next-auth/react';
import React, { useEffect, useMemo, useState } from 'react';
import { getFullName, getRelativeDate, htmlParser } from 'utls';

export const CardSubComment = ({
  id,
  author,
  content,
  current_vote,
  date,
  votes,
  commentsRefetch,

  author_avatar_urls,
  author_name,
  author_url
}) => {
  const { avatar_url, firstname, lastname } = author;

  const fullName = useMemo(
    () => getFullName(firstname, lastname),
    [firstname, lastname]
  );
  const relativeDate = useMemo(() => getRelativeDate(date), [date]);
  // const [votesData, setVotes] = useState({
  //   upvotes: 0,
  //   downvotes: 0,
  //   currentVote: ''
  // });
  // const callBack = data => {
  //   setVotes({ ...data?.votes, currentVote: data?.actionType });
  // };

  // useEffect(() => {
  //   if (votes) setVotes({ ...votes, currentVote: current_vote });
  // }, [votes]);

  const { data: session } = useSession();

  return (
    <Box display={'flex'}>
      <Avatar
        src={author_avatar_urls['400'] ?? author_avatar_urls['250']}
        name={author_name ?? ''}
        size={'sm'}
        loading='lazy'
      />
      <VStack
        p='0 16px 0 16px'
        backgroundColor='#F1EFE2'
        borderRadius={'5px'}
        w='full'
        align={'flex-start'}
      >
        <Flex gap={2} w='fit-content' align='center'>
          <Box>
            <Text as='span' fontSize={'sm'} fontWeight='bold'>
              {author_name ?? ''}
            </Text>
          </Box>
          <Box color='#999999' fontSize='md' fontWeight={'bold'}>
            &#183;
          </Box>
          <Text as='span' fontSize={'sm'} color='#9597a1'>
            {relativeDate}
          </Text>
        </Flex>
        <HStack align={'stretch'} spacing={4} w={'100%'}>
          <Text as='p' fontSize={'md'}>
            {htmlParser(content?.rendered)}
          </Text>
          <Spacer />
          <ActionBar
            {...{
              id,
              // current_vote: votesData?.currentVote,
              // votes: votesData,
              commentsRefetch,
              currentUser: session?.user?.id,
              author,
              content
            }}
            // dataCallBack={callBack}
          />
        </HStack>
      </VStack>
    </Box>
  );
};

const ActionBar: React.FC<
  { onAddCommentOpen: () => void; onRepliesOpen: () => void } & Pick<
    Props,
    'ID' | 'level' | 'votes' | 'current_vote' | 'replies' | 'commentsRefetch'
  >
> = ({
  id,
  // level,
  onAddCommentOpen,
  votes,
  current_vote,
  onRepliesOpen,
  replies,
  commentsRefetch,

  content,
  currentUser,

  author,
  dataCallBack
}) => {
  // const { upvotes, downvotes } = votes;
  const contentHtml = content?.rendered?.replace(/<[^>]+>/g, '');

  const [editAnswer, setEditAnswer] = useState(false);
  return (
    <HStack align={'center'}>
      {/* <UpVoteButton
        refType='comment'
        refId={ID}
        count={upvotes}
        currentVote={current_vote}
        size={'sm'}
        callback={dataCallBack}
      />
      <Box color='#999999' fontSize='md' fontWeight={'bold'}>
        &#183;
      </Box>
      <DownVoteButton
        refType='comment'
        refId={ID}
        count={downvotes}
        currentVote={current_vote}
        size={'sm'}
        callback={dataCallBack}
      /> */}
      {/* <Box color='#999999' fontSize='md' fontWeight={'bold'}>
        &#183;
      </Box> */}

      {/* <AddCommentButton
        onClick={replies?.length === 0 ? onAddCommentOpen : onRepliesOpen}
        size={'sm'}
        count={replies?.length}
      /> */}
      <Box />
      <Spacer />
      {/* {author === currentUser ? (
        <ActionsMenu
          type='comment'
          id={id}
          editAnswer={editAnswer}
          setEditAnswer={setEditAnswer}
          content={contentHtml}
          setIsEditQuestion={() => {}}
          setIsOpenAskModal={() => {}}
        />
      ) : (
        ''
      )} */}
    </HStack>
  );
};

// const ActionBar: React.FC<
//   Pick<Props, 'ID' | 'votes' | 'current_vote' | 'commentsRefetch'>
// > = ({ id, votes, current_vote, commentsRefetch, dataCallBack }) => {
//   const { upvotes, downvotes } = votes;

//   return (
//     <HStack align={'center'} width='100%'>
//       <UpVoteButton
//         callback={dataCallBack}
//         refType='comment'
//         refId={ID}
//         count={upvotes}
//         currentVote={current_vote}
//         size={'sm'}
//       />
//       <Box color='#999999' fontSize='md' fontWeight={'bold'}>
//         &#183;
//       </Box>
//       <DownVoteButton
//         callback={dataCallBack}
//         refType='comment'
//         refId={ID}
//         count={downvotes}
//         currentVote={current_vote}
//         size={'sm'}
//       />

//       <Spacer />
//       <ReportMenu reportType='comment' reportId={ID} />
//     </HStack>
//   );
// };
