import { getCookie } from 'cookies-next';

export const USER_TOKEN_KEY = 'arageek-magazine-user-token';
export const getSSRToken = context => {
  const { req, res } = context;

  return getCookie(USER_TOKEN_KEY, { req, res });
};

export function setCookieFn(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};
