import { createIcon } from '@chakra-ui/icon';

export const Burger = createIcon({
  displayName: 'Burger',
  path: (
    <g fill='currentColor'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.733 10.933c0-.257.21-.466.467-.466h9.333a.467.467 0 1 1 0 .933H4.2a.467.467 0 0 1-.467-.467ZM1.867 7.2c0-.258.209-.467.466-.467h9.334a.467.467 0 0 1 0 .934H2.333a.467.467 0 0 1-.466-.467ZM0 3.467C0 3.209.209 3 .467 3H9.8a.467.467 0 0 1 0 .933H.467A.467.467 0 0 1 0 3.467Z'
      />
    </g>
  ),
  viewBox: '0 0 14 14'
});
