import { AspectRatio, AspectRatioProps, ImageProps } from '@chakra-ui/react';
import NextImage from 'next/future/image';
// import { cloudflareLoader } from 'utls';

const aspectRatioProps: AspectRatioProps = {
  as: 'figure',
  overflow: 'hidden',
  w: '24px',
  maxW: '24px',
  ratio: 1,
  bgColor: 'whiteAlpha.50',
  rounded: 'full'
};

const imageProps: ImageProps = {
  objectFit: 'cover',
  objectPosition: 'center',
  w: 'full',
  h: 'full'
};

export const Avatar = ({ src, name, increaseWidth }) => {
  return (
    <AspectRatio
      {...aspectRatioProps}
      sx={{
        '.arageek-image': {
          ...imageProps
        }
      }}
      width={increaseWidth ? 12 : 8}
      height={increaseWidth ? 12 : 8}
      maxW={increaseWidth ? '100%  ' : 'auto'}
    >
      <NextImage
        className='arageek-image'
        src={src}
        alt={name}
        width={increaseWidth ? 12 : 24}
        height={increaseWidth ? 12 : 24}
        // loader={cloudflareLoader}
      />
    </AspectRatio>
  );
};
