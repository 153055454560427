import {
  Box,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  Icon,
  Text
} from '@chakra-ui/react';
import { ArrowLeft2, Home } from 'iconsax-react';
import { useMedia } from 'react-use';
import { NextChakraIconButton, NextChakraLink } from '../wrappers';
import { useTranslation } from 'hooks';

export const Breadcrumb: React.FC = ({ breadcrumb }: any) => {
  const { locale } = useTranslation();
  if (!Array.isArray(breadcrumb)) {
    return null;
  }

  const isMobile = useMedia('(max-width: 700px)', true);
  const isEnglish = locale === 'en';

  return (
    <>
      <ChakraBreadcrumb
        width='auto'
        overflowX='scroll'
        sx={{
          ol: {
            display: 'flex',
            width: isMobile ? '120vh' : '100%',
            overflowX: 'auto'
          },
          scrollbarColor: 'white white',
          '&::-webkit-scrollbar': {
            width: '3',
            display: 'none'
          },

          '&::-webkit-scrollbar-thumb': {
            bgColor: '#bdbaba',
            borderRadius: 'full',
            borderColor: 'white',
            borderWidth: '3',
            borderStyle: 'solid'
          }
        }}
        separator={
          <Icon
            display='block'
            as={ArrowLeft2}
            transform={isEnglish && 'rotate(180deg)'}
          />
        }
      >
        {breadcrumb?.map(({ position, name, item = '/' }, index) => {
          const isLastItem = index === breadcrumb.length - 1;
          return (
            <BreadcrumbItem
              key={position}
              isCurrentPage={isLastItem}
              isLastChild={isLastItem}
              {...(isLastItem && {
                overflow: 'hidden'
              })}
            >
              {position === 1 ? (
                <NextChakraIconButton
                  variant={'ghost'}
                  icon={<Icon as={Home} />}
                  href={item}
                  aria-label={name}
                />
              ) : (
                <NextChakraLink
                  {...(isLastItem && {
                    'aria-current': 'page',
                    pointerEvents: 'none',
                    _before: { content: 'none' },
                    _hover: {
                      cursor: 'default'
                    }
                  })}
                  href={item}
                  noOfLines={1}
                >
                  <Text
                    width={isLastItem ? '90%' : '100%'}
                    as='span'
                    dangerouslySetInnerHTML={{ __html: name }}
                  />
                </NextChakraLink>
              )}
            </BreadcrumbItem>
          );
        })}
      </ChakraBreadcrumb>
    </>
  );
};
