import { Heading } from '@chakra-ui/react';
import { NextChakraLinkOverlay } from 'components/wrappers';

interface Props {
  title?: string;
  hasUrl?: boolean;
  questionUrl: string;
}

export const QuestionTitle: React.FC<Props> = ({
  title,
  hasUrl,
  questionUrl,
}) => {
  if (!title) {
    return null;
  }

  return (
    <Heading as='h1' fontSize={'lg'} position='relative'>
      {hasUrl ? (
        <NextChakraLinkOverlay href={questionUrl}>
          {title}
        </NextChakraLinkOverlay>
      ) : (
        title
      )}
    </Heading>
  );
};
