import { useMutation, useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { useToast, useTranslation } from 'hooks';
import { mutationFn } from 'utls';

interface UseComments {
  refetch: () => void;
  mutateAddComment: (params) => void;
  mutateDeleteComment: (params) => void;
  mutateEditComment: (params) => void;
  comments: Comment[];
  isLoading: boolean;
}

export const useComments = (object_id: string): UseComments => {
  const { t } = useTranslation();
  const toast = useToast();
  const { data: session } = useSession();
  const { refetch, data, isLoading } = useQuery([
    'comments',
    { post: object_id, page: 1, per_page: 100 },
    session?.accessToken
  ]);

  const comments = data?.data ?? [];
  const { mutateAsync: mutateAddComment } = useMutation(
    (params: any) =>
      mutationFn({
        name: `comments`,
        params,
        token: session?.accessToken,
        namespace: 'wp/v2'
      }),
    {
      onSuccess: () => {
        refetch();
        toast({
          title: t('bio.seccessful_op'),
          description: t('bio.comment_added'),
          status: 'success'
        });
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateDeleteComment } = useMutation(
    (params: any) =>
      mutationFn({
        name: `delete-comment`,
        params,
        token: session?.accessToken,
        namespace: 'wp/v2'
      }),
    {
      onSuccess: () => {
        refetch?.();
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateEditComment } = useMutation(
    (params: any) =>
      mutationFn({
        name: `edit-comment`,
        params,
        token: session?.accessToken,
        namespace: 'wp/v2'
      }),
    {
      onSuccess: () => {
        refetch();
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  return {
    refetch,
    isLoading,
    comments,
    mutateAddComment,
    mutateDeleteComment,
    mutateEditComment
  };
};
