import { Box, BoxProps, Center, Icon, IconButton } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTranslation } from 'hooks';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  gap?: BoxProps['gap'];
  hideBar?: boolean;
  buttons?: boolean;
  gallery?: boolean;
  children?: any;
  t?: (prop: string) => string;
}

export const Slider: React.FC<Props> = ({
  children,
  gap,
  hideBar,
  buttons
}) => {
  const { locale } = useTranslation();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    direction: locale === 'en' ? 'ltr' : 'rtl',
    dragFree: false,
    containScroll: 'trimSnaps',
    draggable: Array.isArray(children) && children.length > 1
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  useEffect(() => {
    if (!emblaApi) return;
    onSelect();

    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <Box pos={'relative'}>
      <Box
        ref={emblaRef}
        overflowX='hidden'
        px={2}
        py={4}
        sx={{
          scrollbarColor: 'white white',
          '&::-webkit-scrollbar': {
            width: '3',
            display: hideBar ? 'none' : 'initial'
          },

          '&::-webkit-scrollbar-thumb': {
            bgColor: '#bdbaba',
            borderRadius: 'full',
            borderColor: 'white',
            borderWidth: '3',
            borderStyle: 'solid',
            display: hideBar ? 'none' : 'initial'
          }
        }}
      >
        <Box display={'flex'} gap={gap} alignItems={'center'}>
          {children}
        </Box>
      </Box>

      {buttons && (
        <>
          <SliderButton
            type='prev'
            onClick={scrollPrev}
            isEnabled={prevBtnEnabled}
          />
          <SliderButton
            type='next'
            onClick={scrollNext}
            isEnabled={nextBtnEnabled}
          />
        </>
      )}
    </Box>
  );
};

interface SliderButtonProps {
  type: 'next' | 'prev';
  onClick: () => void;
  isEnabled: boolean;
  gallery?: boolean;
}

const arrows = {
  next: ArrowLeft2,
  prev: ArrowRight2
};

const dir = {
  prev: 'to-r',
  next: 'to-l'
};

const SliderButton: React.FC<SliderButtonProps> = ({
  type,
  onClick,
  isEnabled,
  gallery
}) => {
  const { t } = useTranslation();
  const ariaLabels = {
    next: t('bio.the_next'),
    prev: t('bio.the_prev')
  };

  const isNext = type === 'next';
  return (
    <Box>
      <Center
        zIndex={10}
        position={'absolute'}
        insetY={0}
        right={isNext ? 0 : 'auto'}
        left={isNext ? 'auto' : 0}
        transform='auto'
        translateX={{
          base: isNext ? '-40%' : '40%',
          lg: isNext ? '-50%' : '50%'
        }}
        opacity={isEnabled ? 1 : 0}
        visibility={isEnabled ? 'visible' : 'hidden'}
        transitionDuration='ultra-slow'
        transitionProperty={'all'}
        transitionTimingFunction='ease-in-out'
        pointerEvents='none'
        bgGradient={
          gallery
            ? `linear(${dir?.[type]}, whiteAlpha.900, transparent)`
            : 'none'
        }
      >
        <IconButton
          colorScheme={'dark'}
          pointerEvents='auto'
          icon={<Icon as={arrows?.[type]} />}
          aria-label={ariaLabels?.[type]}
          onClick={onClick}
        />
      </Center>
    </Box>
  );
};
