import type { IconProps } from '@chakra-ui/react';
import { Icon, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'hooks';
import { Verify } from 'iconsax-react';

export const VerifiedBadge: React.FC<IconProps> = () => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t('bio.verified_account')}>
      <Icon as={Verify} ml={1} color='blue.500' />
    </Tooltip>
  );
};
