import { Avatar, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { NextChakraLink } from 'components/wrappers';
import { useEffect } from 'react';
import { User } from 'types/users';
// import type { User } from 'types';
import { getFullName } from 'utls';
import { FollowButton } from './shared';

export const UserItem: React.FC<User> = ({
  ID,
  slug,
  job,
  firstname,
  lastname,
  avatar_url,
  followed,
  index
}) => {
  const fullName = getFullName(firstname, lastname);

  return (
    <VStack as='article' align={'stretch'} w='full' spacing={4} h='full'>
      <Center>
        <Avatar name={fullName} src={avatar_url} size='lg' loading='lazy' />
      </Center>
      <VStack align={'stretch'} w='full' spacing={2} h='full'>
        <Heading
          as='h1'
          fontSize={'md'}
          textAlign='center'
          fontWeight='medium'
          // lineHeight="1.2"
          whiteSpace={'nowrap'}
          overflow='hidden'
          textOverflow={'ellipsis'}
        >
          <NextChakraLink href={`/profile/${slug}`} color='dark.700'>
            {fullName}
          </NextChakraLink>
        </Heading>
        <Text
          fontSize={'sm'}
          color='dark.600'
          lineHeight='none'
          textAlign='center'
          whiteSpace={'nowrap'}
          overflow='hidden'
          textOverflow={'ellipsis'}
        >
          {job}
        </Text>
        <Center>
          <FollowButton {...{ ID, followed }} index={index} />
        </Center>
      </VStack>
    </VStack>
  );
};
