import { VStack } from '@chakra-ui/react';

import { useMutation } from '@tanstack/react-query';
import { Form, Radio, SubmitButton, Textarea } from 'components/forms-elements';
import { FormikConfig, useField } from 'formik';
import { useToast, useTranslation } from 'hooks';
import { mutationFn, yupValidationRules } from 'utls';
import * as Yup from 'yup';

export const WikiDislikeForm: React.FC = ({ id, callback }) => {
  const toast = useToast();
  const { locale, t } = useTranslation();
  const validationRules = yupValidationRules(locale);
  const validationSchema = Yup.object({
    reason: validationRules.string,
    reasonText: validationRules.stringOptional.when('reason', {
      is: 'other',
      then: validationRules.string
    })
  });

  const options = {
    notIncludingWhatINeed: t(
      'general.forms.wiki_dislike_form.notIncludingWhatINeed'
    ),
    inclucingWrongInforamtions: t(
      'general.forms.wiki_dislike_form.inclucingWrongInforamtions'
    ),
    other: t('general.forms.wiki_dislike_form.other')
  };

  const { mutateAsync: mutateAddComment } = useMutation(
    params => mutationFn({ name: `comments`, params }),
    {
      onSuccess: ({ data, params }) => {
        toast({
          title: t('general.toastMessages.thanks'),
          description: t('general.toastMessages.review_submitted_successfully')
        });
        callback?.();
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    { reason, reasonText },
    { resetForm }
  ) => {
    try {
      const params = {
        post: id,
        content: reason === 'other' ? reasonText : options[reason]
      };

      await mutateAddComment(params);
    } catch (error) {
      resetForm();
    }
  };

  return (
    <Form
      initialValues={{
        reason: '',
        reasonText: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      hasDevTools={false}
      enableReinitialize
    >
      <VStack align={'stretch'} spacing={8}>
        <VStack align={'stretch'} spacing={4}>
          <Radio options={options} name='reason' />
          <ConditionalTextarea />
        </VStack>
        <VStack align={'stretch'} spacing={4}>
          <SubmitButton>{t('general.forms.send')}</SubmitButton>
        </VStack>
      </VStack>
    </Form>
  );
};

const ConditionalTextarea = () => {
  const [{ value }] = useField('reason');

  if (value !== 'other') {
    return null;
  }

  return <Textarea name='reasonText' label='' />;
};
