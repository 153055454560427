import { defaultQueryFn } from 'utls';

const defaultParams = {
  namespace: 'yarpp/v1/related',
  _embed: 1
};

export const getRelatedItems = async (id: any, limit = 9) => {
  const { data: items } = await defaultQueryFn({
    queryKey: [
      id,
      {
        ...defaultParams,
        limit
      }
    ]
  });

  return items;
};
