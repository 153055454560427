import * as Yup from 'yup';

export const errMessages = {
  en: {
    required: 'This field is required, please fill it in',
    requiredSelect: 'Please select an option',
    privacy: 'Consent required to continue',
    numb: 'Please enter a valid number',
    phone: 'Please enter a valid phone number',
    email: 'Please enter a valid email',
    userName: 'This username is already registered',
    onlyLatin: 'Only English letters are allowed',
    url: 'Links are allowed only'
  },
  ar: {
    required: 'هذا الحقل مطلوب, يرجى تعبئته',
    requiredSelect: 'يرجى إختيار أحد الخيارات',
    privacy: 'الموافقة مطلوبة للمتابعة',
    numb: 'يرجى إدخال رقم صالح',
    phone: 'يرجى إدخال رقم هاتف صالح',
    email: 'يرجى إدخال بريد إلكتروني صالح',
    userName: 'اسم المستخدم هذا مسجّل مسبقاً',
    onlyLatin: 'يسمح فقط بإستخدم الحروف الإنكليزية',
    url: 'يسمح بإدخال الروابط فقط'
  }
};

export const yupValidationRules = (lang = 'ar') => {
  return {
    number: Yup.number().required(errMessages?.[lang]?.required),
    string: Yup.string().required(errMessages?.[lang]?.required),
    stringOptional: Yup.string(),
    pin: Yup.number()
      .required(errMessages?.[lang]?.required)
      .min(4, errMessages?.[lang]?.required),
    select: Yup.string().required(errMessages?.[lang]?.requiredSelect),
    email: Yup.string()
      .email(errMessages?.[lang]?.email)
      .required(errMessages?.[lang]?.required),
    emailOptional: Yup.string().email(errMessages?.[lang]?.email)
  };
};
