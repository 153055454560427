import {
  Divider,
  HStack,
  ModalFooter,
  VStack,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Edit2, More, Trash } from 'iconsax-react';
import { useRouter } from 'next/router';
import React from 'react';
import { useSession } from 'next-auth/react';
import axios from 'axios';

import { useDelete, useToast, useTranslation } from 'hooks';
import { EditAnswerModal } from './EditAnswerModal';

interface Props {
  type: 'question' | 'answer' | 'comment' | 'user' | 'blog' | 'quiz';
  id?: string;
  editAnswer?: Boolean;
  setEditAnswer: (editAnswer) => void;
  isEditQuestion?: Boolean;
  setIsEditQuestion: (isEditQuestion) => void;
  content?: string;
  isOpenAskModal?: Boolean;
  setIsOpenAskModal: (isOpenAskModal) => void;
  slug?: string;
}

export const ActionsMenu: React.FC<Props> = ({
  id,
  editAnswer,
  setEditAnswer,
  type,
  content,
  setIsEditQuestion,
  isEditQuestion,
  setIsOpenAskModal,
  isOpenAskModal,
  slug,
  commentsRefetch,

  ...rest
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { data: session } = useSession();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const params: any = {
    type,
    id,
    action: type
  };
  const { mutateAsync: mutateDelete } = useDelete(params);
  const headers = {
    Authorization: `Bearer ${session?.accessToken}`
  };

  const { mutateAsync: mutateDeleteComment } = useMutation(
    (params: any) => {
      return axios.delete(
        `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/wp-json/wp/v2/comments/${id}`,
        {
          headers: headers
        }
      );
    },
    {
      onSuccess: () => {
        toast({
          title: t('bio.seccessful_op'),
          description: t('bio.comment_deleted'),
          status: 'success'
        });
        commentsRefetch();
      },
      onError: error => {
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      }
    }
  );

  const onSubmitDeleteComment = () => {
    mutateDeleteComment();
    onClose();
  };
  const router = useRouter();

  return (
    <React.Fragment>
      <Popover isLazy direction='rtl'>
        <PopoverTrigger>
          <IconButton
            icon={<Icon as={More} />}
            aria-label={t('bio.list')}
            variant={'ghost'}
            size='md'
            {...rest}
          />
        </PopoverTrigger>
        <PopoverContent rounded='md' w='auto'>
          <PopoverArrow />
          <PopoverBody p={2}>
            <VStack align={'stretch'} spacing={1} py='1'>
              <Button
                leftIcon={<Icon as={Edit2} />}
                variant={'ghost'}
                size='sm'
                onClick={() => {
                  if (type === 'question') {
                    setIsEditQuestion(true);
                    setIsOpenAskModal(true);
                  }
                  if (type === 'blog') {
                    router.push(`/blogs/${slug}/edit`);
                  } else {
                    setEditAnswer(true);
                  }
                }}
              >
                {t('bio.edit')}
              </Button>
              <Divider />
              <Button
                leftIcon={<Icon as={Trash} />}
                colorScheme='red'
                variant={'ghost'}
                size='sm'
                onClick={onOpen}
              >
                {t('bio.delete')}
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('bio.delete')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t('bio.are_you_sure')}</ModalBody>
          <ModalFooter>
            <HStack spacing={4}>
              <Button
                onClick={() => {
                  onSubmitDeleteComment();
                }}
              >
                {t('bio.yes')}
              </Button>
              <Button colorScheme='red' onClick={onClose}>
                {t('bio.cancel')}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <EditAnswerModal
        {...{
          editAnswer,
          setEditAnswer,
          id,
          refetch: commentsRefetch,
          content,
          type
        }}
      />
    </React.Fragment>
  );
};
