import {
  Box,
  Button,
  Center,
  Collapse,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useTranslation } from 'hooks';
import { htmlParser } from 'utls';

interface Props {
  content: string;
}

export const AnswerContent: React.FC<Props> = ({ content }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen } = useDisclosure();

  return (
    <Box pos={'relative'}>
      <Collapse startingHeight={100} in={isOpen}>
        <VStack py={4} align={'stretch'}>
          {htmlParser(content)}
        </VStack>
      </Collapse>

      {!isOpen && content.length > 50 && (
        <Center
          py={1}
          pos={'absolute'}
          insetX={0}
          bottom={0}
          zIndex={1}
          bgGradient='linear(to-t, white 0%, whiteAlpha.800 80%,transparent 100%)'
        >
          <Button size='sm' onClick={onOpen} variant='ghost'>
            {t('bio.keep_reading')}
          </Button>
        </Center>
      )}
    </Box>
  );
};
