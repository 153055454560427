import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';

import { Providers } from 'context';
import 'focus-visible/dist/focus-visible';
import Script from 'next/script';

function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
        />
      </Head>
      <SessionProvider session={pageProps.session}>
        <Providers {...{ pageProps }}>
          <Component />
        </Providers>
      </SessionProvider>
      <Script
        id='remove-sw'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          const unregister = async () => {
            const workers = await navigator.serviceWorker.getRegistrations();
              for (const worker of workers) {
                if (worker.active && worker.active.scriptURL.includes('/sw.js')) {
                worker.unregister();
                console.log('unregistering service worker');
                console.log('worker.unregister');
              }
            };
          };
          unregister();
          `
        }}
      />
    </>
  );
}

export default App;
