import approx from 'approximate-number';

export const approximateNumber = (num: string | number): string => {
  if (!num) {
    return '';
  }
  const formatedNum = approx(Number(num)) as string;

  return formatedNum
    .replace('k', ' الف')
    .replace('m', ' مليون')
    .replace('b', ' مليار');
};
